<script lang="ts">
	import type { GetSectionsCoreQuery } from "src/graphql-operations";
	import { GetOutputsCoresBySection } from "src/graphql-operations";
	import ToolsDetails from "./tools-details.svelte";

	export let section: GetSectionsCoreQuery["sections"][0];

	const outputsStore = GetOutputsCoresBySection({
		variables: { sectionId: section.id },
	});
</script>

{#if !$outputsStore.loading}
	{#each $outputsStore.data?.outputs as output}
		<div class="flex w-full flex-col md:flex-row">
			<div
				style="color: var(--color-{section.slug}-500);"
				class="flex flex-col text-left md:w-1/4"
			>
				<div class="flex w-full flex-row items-center">
					<figure class="h-5 w-5 lg:h-10 lg:w-10">
						<img
							class="m-auto max-h-full max-w-full"
							alt={output.name}
							src="/img{output.icons.find((icon) => icon.type === 'small').file
								.path}"
						/>
					</figure>
					<h1
						class="mx-2 text-2xl font-semibold tracking-tight md:text-xl lg:text-2xl"
					>
						{output.name}
					</h1>
				</div>
				<p class="my-3 mb-5 text-neutral-500">
					{output.intro.split(".").slice(0, 2).join(". ") + "."}
				</p>
				<!-- <a href="/{section.slug}/{output.slug}">
					<button class="button w-full md:w-40">Ver output</button>
				</a> -->
			</div>
			<div class="mt-4 md:mt-0 md:w-3/4 md:px-4">
				<ToolsDetails {output} section={section.slug} />
			</div>
		</div>
	{/each}
{/if}
