<script lang="ts">
	import type { GetOutputsCoresBySectionQuery } from "src/graphql-operations";
	import {
		CountAllToolsDoneByTeamId,
		GetToolsCoreByOutput,
	} from "src/graphql-operations";
	import userStore from "src/stores/user";
	import ToolCard from "../../common/content/tool-card.svelte";

	export let output: GetOutputsCoresBySectionQuery["outputs"][0],
		section: string;

	let completedTools = [];

	const toolsStore = GetToolsCoreByOutput({
		variables: { outputId: output.id },
	});

	const getAllCompletedTools = CountAllToolsDoneByTeamId({
		variables: { team_id: userStore.getSelectedProjectId() },
	});

	$: {
		const { loading, data } = $getAllCompletedTools;
		if (!loading && data) {
			completedTools = data?.questionnaires_teams_states.map((tool) => {
				return tool.questionnaire.tool_id;
			});
		}
	}
</script>

<div class="grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-5">
	{#if !$toolsStore.loading}
		{#each $toolsStore.data?.outputs_tools as outputTool, index}
			{#if index === 0}
				<ToolCard
					color={section}
					hoverable={false}
					href="/{section}/{output.slug}/{outputTool.tool.slug}"
					{index}
					{outputTool}
					selected={completedTools.includes(outputTool.tool.id)}
				/>
			{:else}
				<ToolCard
					color={section}
					hoverable={false}
					href="#"
					{index}
					{outputTool}
					selected={completedTools.includes(outputTool.tool.id)}
				/>
			{/if}
		{/each}
	{/if}
</div>
