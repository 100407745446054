<script lang="ts">
	import type { GetSectionsCoreQuery } from "src/graphql-operations";

	type Section = GetSectionsCoreQuery["sections"][0];

	export let sections: Section[], filteredSections: { [id: string]: Section };

	function select(section) {
		filteredSections = { [section.id]: section }; // reatividade do svelte.
	}
	function selectAll() {
		filteredSections = Object.assign(
			{},
			...sections.map((s) => ({ [s.id]: s })),
		);
		filteredSections = filteredSections;
	}

	let colors = {
		think: {
			text: "text-think-700",
			bg: "bg-think-100",
		},
		experience: {
			text: "text-experience-700",
			bg: "bg-experience-100",
		},
		manage: {
			text: "text-manage-700",
			bg: "bg-manage-100",
		},
	};

	selectAll();
</script>

<div class="flex space-x-2">
	<!-- {#each sections as section} -->
	<!-- <div
			class="filter-card my-2 cursor-pointer rounded-lg px-1 py-1 text-center transition-all
			       hover:brightness-105 md:px-3 {colors[section.slug].text} {colors[
				section.slug
			].bg}"
			class:opacity-50={!(section.id in filteredSections)}
			on:click={() => select(section)}
		>
			{section.name}
		</div> -->
	<!-- {/each} -->
	<!-- <div
		class="mx-1 my-2 cursor-pointer rounded-lg bg-think-100 px-1 py-1 text-center text-think-700
	       transition-all hover:brightness-105 md:px-3"
		on:click={selectAll}
	>
		Ver Tudo
	</div> -->
</div>
