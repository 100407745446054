<script lang="ts">
	import type { GetSectionsCoreQuery } from "src/graphql-operations";
	import { GetSectionsBasic } from "src/graphql-operations";
	import userStore from "src/stores/user";
	import OutputsBySection from "./outputs-by-section.svelte";
	import SectionSelectableFilter from "./section-selectable-filter.svelte";

	type Section = GetSectionsCoreQuery["sections"][0];

	const sectionsStore = GetSectionsBasic({
		variables: { team_id: userStore.getSelectedProjectId() },
	});
	let filteredSections: { [id: string]: Section } = {};
</script>

<section class="mt-12 w-full text-left">
	<!-- <h1 class="my-6 text-xl font-semibold tracking-tight">
		Conheça e aplique as ferramentas
	</h1> -->
	{#if !$sectionsStore.loading}
		<SectionSelectableFilter
			sections={$sectionsStore.data?.sections}
			bind:filteredSections
		/>
		<div class="mt-5 flex flex-col space-y-16">
			{#each $sectionsStore.data?.sections as section}
				{#if section.id in filteredSections}
					<OutputsBySection {section} />
				{/if}
			{/each}
		</div>
	{/if}
</section>
